body,div,p,h1,h2,h3,h4,h5,h6,ul,li,ol,a,i,b,em,strong,span,img,button,section,input,textarea,form{ 
    padding:0;margin:0;
    font-family: 'Microsoft YaHei','SF Pro Display',Roboto,Noto,Arial,'PingFang SC',sans-serif !important;
}
/* span {
    font-size: 12px;
} */
h1,h2,h3,h4,h5,h6{
    font-weight: normal;
}
i {
    font-style: normal;
}
.red {
    color: #D0021B;
}
.weight {
    font-weight: bold;
}
a{ text-decoration:none;color:#333; }
a:hover{ color: #D0021B;}
li{ list-style:none; }
.fl{ float:left; }
.fr{ float:right; }
.clearfix:after{ content:'';display:block;clear:both; }
.clearfix{ zoom:1; }
b{font-weight: normal; }
html {
    --antd-wave-shadow-color: #D0021B;
}
/* html{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
} */
body{ background-color: #f8f8f8;font-size:14px;color:#333; }
img{ display:block; border: none}
input,button{ border:none;outline:none;background: transparent; }
textarea{ resize: none;outline: none;}
body a:hover,body a:link{ text-decoration: none; }
svg {
    line-height: 0;
}
.icon-line-height {
    line-height: 1;
}
.add-pointer,button {
    cursor: pointer;
}
html::-webkit-scrollbar,ul::-webkit-scrollbar,section::-webkit-scrollbar,div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding: 0 2px;
}
html::-webkit-scrollbar-thumb,ul::-webkit-scrollbar-thumb,section::-webkit-scrollbar-thumb,div::-webkit-scrollbar-thumb  {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #d8d8d8;
}
html::-webkit-scrollbar-track,ul::-webkit-scrollbar-track,section::-webkit-scrollbar-track,div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: white;
}

